/* eslint-disable no-console,no-undef,camelcase */
import React, { Component } from "react";
import InputTrigger from "react-input-trigger";
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Image from '../Image';
import _ from 'lodash';
// import { Emoji } from 'emoji-mart'
// import Waiting from '../Waiting';

import { UserDropdownContainer, ProfilePicture, ListDropdown, UserContainer, InputTextArea, DropDownStyledImage,
  ShowTaggedUser, ShoutImage, Cross, ShoutoutDiv, SelectedImage, ProfileImg, ShowTaggedUser2 } from "./styles"; //  ShoutoutContainer, IconContainer,
import { StyledModal, StyledBody, MainContent, PeerButton } from '../RecognitionList/styles';
import {RemoveBuddyContainer, RectBox, CustomInputTextArea} from './AddBuddyPopUp/styles';
import { getUsersToShoutout, getCompanyExemplifiesValues } from '../../redux/actions';
import { /*imgPath,*/ ImageUrl } from '../../utils/constants';
import SocialFeedsEmojiPicker from "../SocialFeedsEmojiPicker";
// import MoreRecognition from "./MoreRecognition";
const iSiOS = !!window.navigator.platform && /iPad|iPhone|iPod/.test(window.navigator.platform);

class SocialFeedTextarea extends Component {
  constructor() {
    super();
    this.state = {
      top: null,
      left: null,
      showSuggestor: false,
      startPosition: null,
      text: '',
      currentSelection: 0,
      employee: [],
      thankYouShoutout: null,
      congratsShoutout: null,
      jobWellDoneShoutout: null,
      textareaValue: '',
      textLength: null,
      trigger: {},
      taggedUser: '',
      showModal: false,
      emojiId: null,
      emojiNative: null,
      emojiSkin: null,
      emojiData: null,
      profileImageArray: []
    };

    this.backdropRef = React.createRef();

    this.toggleSuggestor = this.toggleSuggestor.bind(this);
    this.handleInput = this.handleInput.bind(this);
    this.handleKey = this.handleKey.bind(this);
  }

  componentDidMount() {
    const { getCompanyExemplifiesValues, employeeData} = this.props;
    if(_.isEmpty(employeeData) && employeeData && employeeData.length === 0)this.fetchUsers();
    this.checkDevice();
    getCompanyExemplifiesValues();
  }

  checkDevice = () => {
    let isAndroid = window.navigator.userAgent.toLowerCase().indexOf("android") > -1;
    if(isAndroid) {
      let triggerObj = {};
      triggerObj.keyCode = 229;
      this.setState({
        trigger: triggerObj
      });
    } else {
      let triggerObj = {};
      triggerObj.keyCode = 50;
      triggerObj.shiftKey = !iSiOS;
      this.setState({
        trigger: triggerObj
      })
    }
  };

  toggleSuggestor(metaInformation) {
    const { hookType, cursor } = metaInformation;
    if (hookType === "start") {
      this.setState({
        showSuggestor: true,
        left: cursor.left,
        top: cursor.top + cursor.height,
        startPosition: iSiOS ? (this.textarea.value.length === 0 ? 1 : this.textarea.value.length) : cursor.selectionStart
      });
    }
    if (hookType === "cancel") {
      this.setState({
        showSuggestor: false,
        left: null,
        top: null,
        text: null,
        startPosition: null
      });
    }
  }

  storeFetchedEmployee = () => {
    this.setState({
      employee: this.props.employeeData
    });
  };

  fetchUsers = () => {
    const { userCompany, fetchUsersToShoutout } = this.props;
    let obj = {};
    obj['company_id'] = userCompany.id;
    obj['name'] = this.state.text;
    obj['limit'] = 6;
    if(!_.isNull(userCompany.id))
      fetchUsersToShoutout(obj, this.storeFetchedEmployee);
  };

  handleInput(metaInformation) {
    if(metaInformation.text.includes('@')){
      this.setState({
        text: '',
        textLength: iSiOS ? metaInformation.text.length + 1 : metaInformation.text.length
      },() => this.fetchUsers());
    }
    else{
      this.setState({
        text: metaInformation.text,
        textLength: iSiOS ? metaInformation.text.length + 1 : metaInformation.text.length
      },() => this.fetchUsers());
    }

  }
  handlePeerInput(e) {
    this.setState({
      text: e.target.value,
      textLength: e.target.value.length,
      showSuggestor: true,
    },() => this.fetchUsers());
  }

  handleKey(event) {
    const { which } = event;
    const { currentSelection, /*employee*/ } = this.state;
    const {employeeData} = this.props;
    if(_.isNaN(currentSelection)) {
      event.preventDefault();
      this.setState({
        currentSelection: 0
      });
    }

    if (which === 40) {
      event.preventDefault();

      this.setState({
        currentSelection: (currentSelection + 1) % employeeData.length,
      });
    }

    if (which === 38 ) { 
      event.preventDefault();
      if(currentSelection - 1 < 0)
        this.setState({
          currentSelection: employeeData?.length - 1,
        })
      else 
        this.setState({
          currentSelection: (currentSelection - 1) % employeeData?.length,
        });
    }

    if (which === 13 && this.state.showSuggestor && employeeData[currentSelection]) {
      event.preventDefault();

      const { /*employee,*/ currentSelection, textLength, startPosition } = this.state;
      const { inspirationQuote, employeeData } = this.props;
      const taggedUser = employeeData[currentSelection].fullname.trim();
      const userId = employeeData[currentSelection].uid;
      const profileImage = employeeData[currentSelection].profile_image;
      const newText = `${inspirationQuote.slice(0,startPosition - 1)}${inspirationQuote.slice(startPosition + textLength, inspirationQuote.length)}`;
      this.setState({
        showSuggestor: false,
        left: null,
        top: null,
        text: '',
        startPosition: null,
        textareaValue: newText,
        currentSelection: 0,
        taggedUser: taggedUser
      }, () => {
        this.props.updateText(newText, userId, taggedUser, true, profileImage)
      });

      // this.endHandler();
    }
  }

  handleClick = () => {
    const { /*employee,*/ currentSelection, textLength, startPosition } = this.state;
    const { inspirationQuote, employeeData } = this.props;
    let userArray = [...this.props.users];
    const taggedUser = employeeData[currentSelection].fullname.trim();
    const profileImage = employeeData[currentSelection].profile_image;
    if(userArray.indexOf(taggedUser) === -1)
      userArray.push(taggedUser);
    const userId = employeeData[currentSelection].uid;
    const newText = `${inspirationQuote.slice(0,startPosition - 1)}${inspirationQuote.slice(startPosition + textLength, inspirationQuote.length)}`;
    this.setState({
      showSuggestor: false,
      left: null,
      top: null,
      text: '',
      startPosition: null,
      textareaValue: newText,
      currentSelection: 0,
      taggedUser: taggedUser
    }, () => {
      this.props.updateText(newText, userId, taggedUser, true, profileImage);
    });
  }

  handleMouseHover = (index) => {
    this.setState({
      currentSelection: index,
    });
  }

  onScroll = (event) => {
    const scrollTop = event.target.scrollTop;
    if(this.backdropRef && this.backdropRef.current) {
      this.backdropRef.current.scrollTop = scrollTop;
    }
  }

  changeModal = () => {
    this.setState((prevState) => ({
      showModal: !prevState.showModal
    }));
  }

  onEmojiSelect = (emoji, id, native, skin) => {
    console.log("\n emoji clicked");
    this.setState({
      emojiData: emoji,
      emojiId: id,
      emojiNative: native,
      emojiSkin: skin
    })
    this.props.addInspirationV2(native);
  }

  onShoutoutSelect = (text, id, image) => {
    const { userIdArray } = this.props;
    this.props.updateShoutoutArray(userIdArray, text, image, id);
    id >= 1 && this.changeModal();
  };

  removeShoutout = () => {
    const { shoutoutText, onRemoveUser, onRemoveShoutout } = this.props;
    !shoutoutText ? onRemoveUser() : onRemoveShoutout(shoutoutText)
  };
  removeSingleUser = (index) => {
    const { onRemoveSingleUser } = this.props;
    onRemoveSingleUser(index)
  };

  fallBack = (e) => {
    e.target.src = '/public/images/default.png'
  }

  render() {        
    const { top, left, showSuggestor, /*employee,*/ currentSelection, trigger} = this.state; // showModal
    const { /*userCompany,*/ inspirationQuote, placeholder, isCommentOrReply, shoutoutText,
      shoutoutImage, taggedUser, enableTrigger, ShowEmojiContainer, exemplifiesValues, selectedImage, wishesAndAnni, profileImageArray, peerRecognition, employeeData} = this.props; //  , userIdArray

    // if(!_.isNull(userCompany.id) && !this.props.employeeData) {
    //   return <Waiting />
    // }
    let taggedUserArray = taggedUser ? taggedUser.split(',') : [];
    return (
      <div>
        <ShoutoutDiv>
          {
            wishesAndAnni && this.props.shoutOutData && this.props.shoutOutData.name && this.props.shoutOutData.name !== "" ? (
              <RemoveBuddyContainer border={1}>
                <ShowTaggedUser2>
                  <ProfileImg margin={"0px 15px 0 0px"}>
                    <img onError={this.fallBack} src={`https://s3.amazonaws.com/images.trainingamigo.com/${this.props.shoutOutData.profile_image}`} />
                  </ProfileImg>
                  {`${this.props.shoutOutData.name ? this.props.shoutOutData.name : ""}`}
                  {/* <Cross src={`${imgPath}wrong-tic.png`} onClick={this.removeShoutout} /> */}
                </ShowTaggedUser2>
              </RemoveBuddyContainer>
            ) : null
          }
          {/* {
            emojiId!==null?
              <div>
                <Emoji emoji={this.state.emojiData} size={16} />
              </div> : null
          } */}
          { this.props.showGivenShoutout && !peerRecognition &&
            <div>
              { _.isNull(shoutoutText) || _.isNull(shoutoutImage) ?
                <ShowTaggedUser>
                  {`${taggedUser ? taggedUser : ""}`}
                  <Cross src={ImageUrl + "/social-feed/recognitionClose.png"} onClick={this.removeShoutout} style={{width:"16px",height:"16px", marginLeft:"20px"}}/>
                </ShowTaggedUser> :
                <ShowTaggedUser>
                  {`${taggedUser ? taggedUser : ""} - ${shoutoutText}`}
                  <ShoutImage src={`${ImageUrl}/${shoutoutImage}`} />
                  <Cross src={ImageUrl + "/social-feed/recognitionClose.png"} onClick={this.removeShoutout} style={{width:"16px",height:"16px", marginLeft:"20px"}} />
                </ShowTaggedUser>
              }
            </div>
          }
        </ShoutoutDiv>
        { peerRecognition && !wishesAndAnni ?
          <RectBox>
            { taggedUserArray && taggedUserArray.length > 0 && profileImageArray.length > 0 ? taggedUserArray.map((data, index) => (
              <ShowTaggedUser key={index} height={1} width paddingValue="1" imgWidth="28px">
                {profileImageArray.length > 0 &&  <img src={`${ImageUrl}/${profileImageArray[index]}`} style={{borderRadius:"50%"}}/>}{`${data ? data : ""}`}
                <Cross src={ImageUrl + "/social-feed/recognitionClose.png"} onClick={() => this.removeSingleUser(index)} style={{width:"16px",height:"16px", marginLeft:"20px"}}/>
              </ShowTaggedUser>
            )) : null
            }
            <CustomInputTextArea
              onKeyDown={this.handleKey}
              peer={1}
              padding={placeholder.includes('reply') ? '13px' : '0'}
              width={placeholder.includes('reply') ? '65%' : '100%'}
              border={'none'}
            >
              <textarea
                placeholder={placeholder}
                onKeyDown={this.handleKey}
                rows="4"
                name="inspirationQuote"
                onChange={(e) => this.handlePeerInput(e)}
                value={this.state.text}
                data-gramm_editor="false"
                className="CustomTextAreaField"
                onScroll={this.onScroll}
                spellCheck="false"
                ref={(node) => this.textarea = node}
              />
              <UserContainer
                showSuggestor={ showSuggestor && this.state.text.length }
                top={ `${top}px` }
                left={ `${left}px` }
                width="350px"
              >
                {employeeData !== null && this.state.text.length>0 && employeeData?.length>0? employeeData.map((user, index) => (
                  <UserDropdownContainer
                    key={index}
                    index={index}
                    onMouseEnter={() => this.handleMouseHover(index)}
                    currentSelection={currentSelection}
                    onClick={this.handleClick}
                  >
                    <ProfilePicture>
                      <DropDownStyledImage width="100%" left height='40px' style={{padding: "5px"}}>
                        <div className="middle" style={{ display: "inline-block"}}>
                          <Image style={{width: "30px", display: "inline-block"}} image={user.profile_image} alt={user.fname} />
                        </div>
                        <ListDropdown
                          index={index}
                          currentSelection={currentSelection}
                        >
                          <span style={{marginLeft: "10px"}}>{ user.fullname }</span>
                        </ListDropdown>
                      </DropDownStyledImage>
                    </ProfilePicture>
                  </UserDropdownContainer>
                )) : <span>No User Found</span>}
              </UserContainer>
            </CustomInputTextArea>
          </RectBox> : null
        }
        {
          selectedImage && selectedImage.length>0 && <SelectedImage>
            <img src={selectedImage} alt="uploadable" />
          </SelectedImage>
        }
        {peerRecognition ? null : wishesAndAnni && this.props.shoutOutData && this.props.shoutOutData.name && this.props.shoutOutData.name !== "" ? null : <InputTextArea
          onKeyDown={this.handleKey}
          isCommentOrReply={isCommentOrReply}
          padding={placeholder.includes('reply') ? '13px' : '0'}
          width={placeholder.includes('reply') ? '65%' : '100%'}
        >
          <InputTrigger
            trigger={trigger}
            onStart={(metaData) => { enableTrigger && this.toggleSuggestor(metaData);}}
            onCancel={(metaData) => {enableTrigger && this.toggleSuggestor(metaData);}}
            onType={(metaData) => { enableTrigger && this.handleInput(metaData);}}
            endTrigger={(endHandler) => {this.endHandler = endHandler;}}
          >
            <textarea
              placeholder={placeholder}
              rows="4"
              name=""
              onChange={(e) => this.props.addInspiration(e)}
              value={inspirationQuote}
              data-gramm_editor="false"
              className="CustomTextAreaField"
              onScroll={this.onScroll}
              spellCheck="false"
              ref={(node) => this.textarea = node}
            />
          </InputTrigger>
          <UserContainer
            showSuggestor={ showSuggestor }
            top={ `${top}px` }
            left={ `${left}px` }
            width="350px"
          >
            {employeeData !== null ? employeeData.map((user, index) => (
              <UserDropdownContainer
                key={index}
                index={index}
                onMouseEnter={() => this.handleMouseHover(index)}
                currentSelection={currentSelection}
                onClick={this.handleClick}
              >
                <ProfilePicture>
                  <DropDownStyledImage width="100%" left height='30px'>
                    <div className="middle">
                      <Image image={user.profile_image} alt={user.fname} />
                    </div>
                    <ListDropdown
                      index={index}
                      currentSelection={currentSelection}
                    >
                      { user.fullname }
                    </ListDropdown>
                  </DropDownStyledImage>
                </ProfilePicture>
              </UserDropdownContainer>
            )) : null}
          </UserContainer>
        </InputTextArea>
        } 
        {
          ShowEmojiContainer ?
            <StyledModal
              show={ShowEmojiContainer}
              onHide={this.props.closeEmojiContainer}
              ref={this.props.myref}
            >
              <StyledBody>
                <MainContent>
                  {exemplifiesValues ? exemplifiesValues.map((recognition) => (
                    <PeerButton
                      value={recognition.core_value}
                      onClick={() => this.onShoutoutSelect(recognition.core_value, recognition.id, recognition.image)}
                      key={recognition.id}
                    >
                      <img src={`${ImageUrl}/${recognition.image}`} height={recognition.image.includes('passionate.png') ? '30px' : '20px'}/>
                      <div>{recognition.core_value}</div>
                    </PeerButton>
                  ))
                    : null
                  }
                </MainContent>
              </StyledBody>
            </StyledModal> : null
        }
        {this.props.displayEmoji ? <SocialFeedsEmojiPicker selectEmoji={this.onEmojiSelect} emojiRef={this.props.emojiRef}/> : null}
      </div>
    );
  }
}

SocialFeedTextarea.propTypes = {
  employeeData: PropTypes.array,
  fetchUsersToShoutout: PropTypes.func,
  addInspiration: PropTypes.func,
  addInspirationV2: PropTypes.func,
  userCompany: PropTypes.object.isRequired,
  inspirationQuote: PropTypes.string,
  updateText: PropTypes.func,
  users: PropTypes.array,
  userIdArray: PropTypes.array,
  placeholder: PropTypes.string,
  updateShoutoutArray: PropTypes.func,
  showGivenShoutout: PropTypes.bool,
  onRemoveShoutout: PropTypes.func,
  isCommentOrReply: PropTypes.string,
  shoutoutImage: PropTypes.string,
  shoutoutText: PropTypes.string,
  taggedUser: PropTypes.string,
  onRemoveUser: PropTypes.func,
  enableTrigger: PropTypes.bool,
  exemplifiesValues: PropTypes.array,
  getCompanyExemplifiesValues: PropTypes.func,
  ShowEmojiContainer: PropTypes.bool,
  myref: PropTypes.object,
  displayEmojiPicker: PropTypes.bool,
  showEmojiPicker: PropTypes.func,
  selectedImage : PropTypes.array,
  shoutOutData: PropTypes.obj,
  wishesAndAnni: PropTypes.number,
  closeEmojiContainer: PropTypes.func,
  profileImageArray: PropTypes.array,
  peerRecognition: PropTypes.number,
  onRemoveSingleUser: PropTypes.func,
  emojiRef: PropTypes.object,
  displayEmoji: PropTypes.bool
};

const mapStateToProps = (state) => ({
  employeeData: state.peopleHome.employeeData,
  exemplifiesValues: state.social.exemplifiesValues
});

const mapDispatchToProps = (dispatch) => ({
  fetchUsersToShoutout: (data, cb) => dispatch(getUsersToShoutout(data, cb)),
  getCompanyExemplifiesValues: () => dispatch(getCompanyExemplifiesValues())
});

export default connect(mapStateToProps, mapDispatchToProps)(SocialFeedTextarea);
